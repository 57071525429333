import React from 'react'
import './smallLoader.css'
const SmallLoader = () => {
  return (
    <div>
      <span className="loader"></span>
    </div>
  )
}

export default SmallLoader
